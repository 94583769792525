<template>
  <div
    class="z-100 bg-white rounded-lg shadow-lg"
    :class="{ 'w-[600px]': props.desktop, 'w-full h-full': props.mobile }"
  >
    <!-- Header -->
    <div
      class="flex justify-between items-center p-4 border-b border-gray-300 w-full"
    >
      <div class="flex justify-start items-center gap-2">
        <WebccIcon name="account/addImage" class="size-6" />

        <h2 class="font-medium text-l">
          {{ $t(`${trPrefix}positionPhoto`) }}
        </h2>
      </div>
      <WebccIcon
        name="account/close"
        class="size-5 cursor-pointer"
        @click="closeModal"
      />
    </div>
    <!-- Body -->
    <div
      class="flex flex-col h-auto px-6 p-6 gap-2 w-full"
      :class="{
        'border-b border-gray-300': image,
        'justify-center': uploadFailed,
      }"
    >
      <!-- Upload content -->
      <div v-if="!uploadFailed">
        <p class="font-normal text-xs text-gray-400 pb-4">
          {{ $t(`${trPrefix}note`) }}
        </p>
        <p class="font-normal text-xs text-gray-400 pb-4">
          {{ $t(`${trPrefix}fileType`, { filetypes: fileTypesPrettier }) }}
        </p>

        <div
          class="flex justify-center items-center h-64 bg-gray-50 rounded-lg cursor-pointer"
        >
          <input
            ref="fileInput"
            type="file"
            :accept="acceptedFileTypes.toString()"
            class="hidden"
            @change="onFileChange"
          />
          <div
            v-if="!image"
            class="flex justify-center items-center gap-3 text-gray-500"
            @click="triggerFileUpload"
          >
            <WebccIcon name="account/plus" class="size-4" />
            <p class="font-normal text-base">
              {{ $t(`${trPrefix}upload`) }}
            </p>
          </div>
          <div v-if="image" class="relative w-full h-64">
            <img
              ref="imageElement"
              :src="image"
              alt="To crop"
              class="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>
        <!-- Zoom Slider -->
        <div v-if="image" class="flex justify-center items-center py-2 mt-2">
          <div class="px-3 py-3 rounded-full bg-gray-200" @click="zoomOut">
            <WebccIcon name="account/remove" class="size-3" />
          </div>
          <input
            ref="zoomSlider"
            v-model="zoomLevel"
            type="range"
            min="0.1"
            max="3"
            step="0.01"
            class="w-full h-0.5 mx-2 bg-gray-200 rounded-lg appearance-none cursor-pointer range-sm dark:bg-gray-200"
            @input="updateZoom"
          />
          <div class="px-3 py-3 rounded-full bg-gray-200" @click="zoomIn">
            <WebccIcon name="account/plus" class="size-3" />
          </div>
        </div>
        <!-- CTAs -->
        <div
          v-if="image"
          class="flex justify-end items-center w-full px-3 pt-3 pb-1"
        >
          <WebccButton
            class="w-fit mb-1 ml-1"
            :label="$t(`${trPrefix}cancel`)"
            @click="closeModal"
          />
          <WebccButton
            variant="theme"
            class="w-fit mb-1 ml-1"
            :label="$t(`${trPrefix}save`)"
            @click="cropAndSubmitImage"
          />
        </div>
      </div>
      <!-- Error content -->
      <UploadAvatarRetry
        v-if="uploadFailed"
        :type="uploadErrorType"
        :accepted-file-types="fileTypesPrettier"
        @close-modal="closeModal"
        @try-again="tryAgain"
      />
    </div>
  </div>
  <div v-if="requestPending">
    <WebccLoaderOverlay />
  </div>
</template>

<script lang="ts" setup>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

const trPrefix = 'guestWorld.components.myaccount.profile.UploadAvatarModal.'

const { t } = useI18n()

const props = defineProps<{
  desktop: boolean
  mobile: boolean
}>()

const emit = defineEmits(['close'])
const fileInput = ref<HTMLInputElement | null>(null)
const image = ref<string | null>(null)
const imageElement = ref<HTMLImageElement | null>(null)
let cropper: Cropper | null = null
const zoomLevel = ref(1)

const uploadFailed = ref(false)
const requestPending = ref(false)
const uploadErrorType = ref<ErrorType | undefined>(undefined)
const acceptedFileTypes = ref<string[]>([
  'image/jpg',
  'image/jpeg',
  'image/png',
])
const isWrongFileType = ref(false)

const fileTypesPrettier = computed(() => {
  return acceptedFileTypes.value.toString().replaceAll('image/', ' ')
})

function closeModal() {
  emit('close')
}

function triggerFileUpload() {
  fileInput.value?.click()
}

function tryAgain() {
  uploadFailed.value = false
  uploadErrorType.value = undefined
  isWrongFileType.value = false
  image.value = null
}

function onFileChange(event: Event) {
  const target = event.target as HTMLInputElement

  const file = target.files?.[0]

  if (file && acceptedFileTypes.value.includes(file?.type)) {
    const reader = new FileReader()
    reader.onload = () => {
      image.value = reader.result as string
      initCropper()
    }
    reader.readAsDataURL(file)
  } else {
    isWrongFileType.value = true
    uploadErrorType.value = 'filetype'
    uploadFailed.value = true
  }
}

function initCropper() {
  if (cropper) {
    cropper.destroy()
  }
  // Wait for the image element to be available before initializing cropper
  nextTick(() => {
    if (imageElement.value) {
      cropper = new Cropper(imageElement.value, {
        aspectRatio: 1,
        viewMode: 0,
        autoCropArea: 0,
        scalable: false,
        zoomable: true,
        dragMode: 'move',
        minCanvasHeight: 400,
        minCropBoxHeight: 250,
        cropBoxResizable: true,
        responsive: true,
        background: false,
        checkOrientation: true,
        ready() {
          const previewContainer = document.querySelector('.cropper-view-box')
          previewContainer!.classList.add('rounded-full')
        },
      })
    }
  })
}

function updateZoom() {
  if (cropper) {
    cropper.zoomTo(parseFloat(zoomLevel.value.toString()))
  }
}

function zoomIn() {
  if (cropper && zoomLevel.value < 3) {
    zoomLevel.value = Math.min(zoomLevel.value + 0.1, 3)
    cropper.zoomTo(zoomLevel.value)
  }
}

function zoomOut() {
  if (cropper && zoomLevel.value > 0.1) {
    zoomLevel.value = Math.max(zoomLevel.value - 0.1, 0.1)
    cropper.zoomTo(zoomLevel.value)
  }
}

function cropAndSubmitImage() {
  if (cropper) {
    cropper.getCroppedCanvas().toBlob(async (blob: Blob | null) => {
      if (blob) {
        const { size } = blob
        if (Number((size / 1048576).toFixed(2)) >= 1) {
          uploadErrorType.value = 'limit'
          uploadFailed.value = true
          return
        }
        const mimeType = blob.type
        const extension = mimeType ? mimeType.split('/')[1] : 'png'
        const file = new File([blob], `avatar.${extension}`, {
          type: blob.type,
        })
        const formData = new FormData()
        formData.append('profile_img', file)
        try {
          requestPending.value = true
          await useUser().uploadAvatar(formData)
          requestPending.value = false
          useToaster().success(t(`${trPrefix}success`), { delay: 300 })
          closeModal()
        } catch (e) {
          console.error('Error uploading avatar: ', e)
          uploadErrorType.value = 'generic'
          uploadFailed.value = true
          requestPending.value = false
        }
      }
    }, 'image/png')
  }
}
</script>
