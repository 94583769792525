<template>
  <WebccModal :unstyled="true" class="p-0" :open="open" @close="closeModal">
    <UploadAvatar :desktop="true" :mobile="false" @close="closeModal" />
  </WebccModal>
</template>

<script lang="ts" setup>
defineProps<{
  open: boolean
}>()

const emit = defineEmits(['close'])

function closeModal() {
  emit('close')
}
</script>
