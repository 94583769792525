<template>
  <div ref="dropdown" class="relative">
    <label
      class="absolute bottom-2 right-2 transform translate-x-1/2 translate-y-1/2 cursor-pointer bg-gray-300 p-1 rounded-full shadow-lg border"
      @click="uploadAvatarRequest"
    >
      <WebccIcon name="account/camera" class="size-3 text-gray-700" />
    </label>
    <!-- Dropdown menu -->
    <div
      v-if="uploadAvatarMenu"
      class="absolute z-1 bg-white rotate-45 w-5 h-5 border-l border-t border-gray-300"
      style="top: 6px; left: 46px"
    ></div>
    <div
      v-if="uploadAvatarMenu"
      class="absolute mt-4 w-48 bg-white rounded-md border border-gray-300 z-150"
    >
      <div class="p-2 shadow">
        <div
          class="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
          @click="onUploadAvatar"
        >
          <WebccIcon
            name="account/addImage"
            class="size-5 mr-2 text-gray-700"
          />
          <span class="text-gray-700">
            {{ $t(`${trPrefix}uploadPhoto`) }}
          </span>
        </div>
        <div
          class="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
          @click="onRemoveAvatar"
        >
          <WebccIcon name="account/trash" class="size-5 mr-2 text-gray-700" />
          <span class="text-gray-700">
            {{ $t(`${trPrefix}removePhoto`) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const trPrefix = 'guestWorld.components.myaccount.profile.UploadAvatarButton.'

const props = defineProps<{
  profileImg?: string
}>()

const emit = defineEmits(['uploadModal', 'removeAvatar'])

const dropdown = ref<HTMLElement | null>(null)
const uploadAvatarMenu = ref(false)

const handleClickOutside = (event: MouseEvent) => {
  if (dropdown.value && !dropdown.value.contains(event.target as Node)) {
    uploadAvatarMenu.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})

function onUploadAvatar() {
  uploadAvatarMenu.value = false
  emit('uploadModal')
}

async function onRemoveAvatar() {
  emit('removeAvatar')
  uploadAvatarMenu.value = false
  await useUser().removeAvatar()
  emit('removeAvatar')
}

function uploadAvatarRequest() {
  if (props?.profileImg) {
    uploadAvatarMenu.value = !uploadAvatarMenu.value
  } else {
    onUploadAvatar()
  }
}
</script>
