<template>
  <div class="flex flex-col justify-center items-center text-center">
    <div
      class="rounded-full w-14 h-14 flex justify-center"
      style="background: #b83d4e"
    >
      <WebccIcon
        name="account/warning"
        class="w-7 text-white"
        @click="closeModal"
      />
    </div>
    <div class="pt-6">
      <span
        v-if="props?.type === 'generic'"
        class="font-medium text-base leading-6"
      >
        {{ $t(`${trPrefix}error`) }}
      </span>
      <span
        v-if="props?.type === 'limit'"
        class="font-medium text-base leading-6"
      >
        {{ $t(`${trPrefix}imgLimit`) }}
      </span>
      <span
        v-if="props?.type === 'filetype'"
        class="font-medium text-base leading-6"
      >
        {{ $t(`${trPrefix}fileType`, { filetypes: acceptedFileTypes }) }}
      </span>
    </div>
    <WebccButton
      variant="theme"
      class="w-fit px-2 py-3 mt-6"
      :label="$t(`${trPrefix}tryAgain`)"
      @click="tryAgain"
    />
  </div>
</template>
<script lang="ts" setup>
const trPrefix = 'guestWorld.components.myaccount.profile.UploadAvatarModal.'
const props = defineProps<{
  type?: ErrorType
  acceptedFileTypes: string
}>()
const emit = defineEmits(['tryAgain', 'closeModal'])

function closeModal() {
  emit('closeModal')
}

function tryAgain() {
  emit('tryAgain')
}
</script>
